import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { map, tap, last, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploaderService {
  uploadUrl = 'http://ec2-54-184-105-249.us-west-2.compute.amazonaws.com/user/profile/photo';

  public progressSource = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient) { }

  private handleError<T>(result?: T): any {
    console.log(result);
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

  getHeaders(token: string): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });
    return headers;
  }

  upload(userId: string, token: string, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('profilePhoto', file);

    const headers = this.getHeaders(token);

    const req = new HttpRequest(
      'POST',
      `${this.uploadUrl}/${userId}`,
      formData,
      {
        reportProgress: true,
        headers,
      }
    );

    return this.http.request(req).pipe(
      map(event => this.getEventMessage(event, file)),
      tap((envelope: any) => this.processProgress(envelope)),
      catchError((err) =>
        this.handleError<string>(err)
      ),
      last(),
    );
  }

  processProgress(envelope: any): void {

    if (typeof envelope === 'number') {
      this.progressSource.next(envelope);
    }
  }

  private getEventMessage(event: HttpEvent<any>, file: File): string | number {

    switch (event.type) {
      case HttpEventType.Sent:
        return `Uploading file '${file.name}' of size ${file.size}.`;
      case HttpEventType.UploadProgress:
        return Math.round((100 * event.loaded) / event.total);
      case HttpEventType.Response:
        return `Success`;
      default:
        return `File '${file.name}' surprising upload event: ${event.type}.`;
    }
  }
}

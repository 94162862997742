
<div class="header-label">Users</div>

<div class="small-bubbles-grid">
  <app-card title="Active Users" [loading]="fetchingUsersEnter" [text]="loadedUsersEnter?.activeUsers" [chartData]="[35, 59, 51, 76, 60, 75]"></app-card>
  <app-card title="Total Users" [loading]="fetchingUsersEnter" [text]="loadedUsersEnter?.totalUsers"></app-card>
  <app-card title="Total Orders" [loading]="fetchingUsersEnter" [text]="loadedUsersEnter?.totalOrders "></app-card>
</div>

<app-datatable title="User Information" [columns]="dataTableColumns" [loading]="fetchingUsersActivity"
  [dataRows]="loadedUsersActivity" [dropDownFirstItem]="dropdownFirstItem"
  [dropDownItems]="dropdownMenu" (sortByItemSelected)="sortByItem=$event">    
 


    <div class="datatable-row" *ngFor="let userActivity of loadedUsersActivity | sortBy:'asc': sortByItem; index as i">  
      <div class="datatable-row-grid">
        <div class="datatable-row-field">{{ userActivity.uid }}</div>
        <div class="datatable-row-field" >{{ userActivity.firstName }} {{
          userActivity.lastName }}</div>
        <div class="datatable-row-field" >{{ userActivity.emailAddress }}
        </div>
        <div class="datatable-row-field city-entry-field" style="left: 680px;">{{
          userActivity.city }}</div>
        <div class="datatable-row-field" >{{ userActivity.createdAt |
          date:'shortDate' }}</div>
        <div class="button-entry-field">
          <button class="history-btn" (click)="onClickTripHistoryBtn(i)">Trip History</button>   
        </div>
      </div>
    </div>

</app-datatable>

<app-users-trip-history-popover [show]="displayTripHistory" (closePopup)="closePopOver()" 
[userActivity]="loadedUsersActivity[selectedUserIndex]"></app-users-trip-history-popover>




import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-driver-vehicle-registration-popover',
  templateUrl: './driver-vehicle-registration-popover.component.html',
  styleUrls: ['./driver-vehicle-registration-popover.component.scss']
})
export class DriverVehicleRegistrationPopoverComponent implements OnInit {

  @Input()
  show = false;

  @Output()
  closePopup: EventEmitter<any> = new EventEmitter();

  displayExpiredNotice: boolean = false;

  close(): void {
    this.closePopup.emit();
  }

  onClickRegistrationImage(): void {
    this.displayExpiredNotice = true;
  }

  constructor() { }

  ngOnInit(): void {
  }

  closePopOver(): void {
    this.displayExpiredNotice = false;
  }

}

<div class="header-label">Dashboard</div>

<div class="top-grid">


  <div class="small-bubbles-grid">
    <app-card [welcome]="true" title="Welcome Back," [loading]="fetchingDashboardEnter" [error]=dashboardEnterError
      [text]="loadedDashboardEnter?.nameFirst" ></app-card>
    <app-card title="Active Orders" [loading]="fetchingDashboardEnter" [error]=dashboardEnterError
      [text]="loadedDashboardEnter?.activeOrders" [chartData]="[35, 59, 51, 76, 60, 75]"></app-card>
    <app-card title="Drivers" [loading]="fetchingDashboardEnter" [error]=dashboardEnterError
      [text]="loadedDashboardEnter?.driverCount" [chartData]="[35, 35, 51, 70, 60, 71]"></app-card>
    <app-card title="Customers" [loading]="fetchingDashboardEnter" [error]=dashboardEnterError
      [text]="loadedDashboardEnter?.customerTotal" [chartData]="[39, 35, 34, 45, 65, 71]"></app-card>

  </div>

  <div class="large-bubbles-grid">
    <app-card [wide]="true" title="Earnings" [dropDownFirstItem]="dropdownFirstTimeFrame"
    [dropDownItems]="dropdownMenuTimeFrame" subtitle="Total Revenue from Rides this Month"
      [loading]="fetchingDashboardEarnings" [error]=dashboardEarningsError
      [text]="loadedDashboardEarnings?.earnings[timeFrame] | currency:'USD'" (displayByKeySelected)="timeFrame=$event"> 
    </app-card>

    <app-card [wide]="true" title="Driver Payments" [dropDownFirstItem]="dropdownFirstTimeFrame"
    [dropDownItems]="dropdownMenuTimeFrame" subtitle="Total Driver Payments this Month"
      [loading]="fetchingDashboardEarnings" [error]=dashboardEarningsError
      [text]="loadedDashboardEarnings?.earnings[driveTimeFrame] | currency:'USD'" (displayByKeySelected)="driveTimeFrame=$event">
    </app-card>
  </div>

</div>

<app-datatable title="Recent Activity" [columns]="dataTableColumns" [loading]="fetchingDashboardActivity"
  [dataRows]="loadedDashboardActivity" [dropDownFirstItem]="dropdownFirstItem" [dropDownItems]="dropdownMenu" (sortByItemSelected)="sortByItem=$event">
  
  <div class="datatable-row" *ngFor="let dashboardActivity of loadedDashboardActivity | sortBy:'asc': sortByItem"
    (click)="onSelectRecentActivityDetails(dashboardActivity.id)"> 
    <div class="datatable-row-grid">
      <div class="status">
        <img src="../../assets/img/global/scheduled.png" *ngIf="dashboardActivity.status === 1">
        <img src="../../assets/img/global/in-transit.png" *ngIf="dashboardActivity.status === 2">
        <img src="../../assets/img/global/delivered.png" *ngIf="dashboardActivity.status === 3">
      </div>
      <div class="datatable-row-field">#{{ dashboardActivity.id }}
      </div>

      <div class="datatable-row-field">
        <div class="marker-pickup"><img src="../../assets/img/global/map-marker-pickup.png"  /></div>
        <div class="marker-dropoff"><img src="../../assets/img/global/map-marker-dropoff2.png "  /></div>
      </div>

      <div class="datatable-row-field">
        <div class="datatable-row-field">{{
          dashboardActivity.addressPickupStreet }}</div>
        <div class="datatable-row-field">{{
          dashboardActivity.addressDropoffStreet }}</div>
      </div>

      <div class="datatable-row-field">{{ dashboardActivity.rideCost |
        currency:'USD' }}</div>
      <div class="datatable-row-field">{{ dashboardActivity.earnings
        | currency:'USD' }}</div>
      <div class="datatable-row-field">{{ dashboardActivity.date }}
      </div>
    </div>
  </div>
</app-datatable>

<app-order-detail-popover [show]="displayDashboardOrderDetails" 
  [tripRating]="tripRating"
  (closePopup)="closePopOver()"
  [loadedOrderDetails]="loadedDashboardOrderDetails"
  [fetchingOrderDetails]="fetchingDashboardActivity"
  [displayOrderDetailsTotal]="displayDashboardOrderDetailsTotal"></app-order-detail-popover>
  
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DriversActivity } from '../drivers/drivers.model';
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons';
import { animate, state, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-driver-detail-popover',
  templateUrl: './driver-detail-popover.component.html',
  styleUrls: ['./driver-detail-popover.component.scss']
})
export class DriverDetailPopoverComponent implements OnInit {

  faCcMastercard = faCcMastercard;

  @Input()
  show = false;

  @Input()
  loadedDriversActivity: DriversActivity[];

  @Input()
  fetchingDriversActivity: boolean = false;

  @Output() closePopup: EventEmitter<any> = new EventEmitter();

  displayInsuranceProof: boolean = false;
  displayVehicleRegistration: boolean = false;
  displayTripHistory: boolean = false;

  close(): void {
    this.closePopup.emit();
  }
  constructor() { }

  ngOnInit(): void {
  }

  onClickViewInsurance(): void {
    this.closePopup.emit();
    this.displayInsuranceProof = true;
  }
  onClickViewVehicleRegistration(): void {
    this.close();
    this.displayVehicleRegistration = true;
  }

  onClickViewTripHistory(): void {
    this.close();
    this.displayTripHistory = true;
  }

  closePopOver(): void {
    this.displayInsuranceProof = false;
    this.displayVehicleRegistration = false;
    this.displayTripHistory = false;
  }

}

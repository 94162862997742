import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { from, of, defer } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { User } from './user.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  user = this.afAuth.user.pipe(
    switchMap((user) => {
      if (!user) {
        return of(null);
      }
      return from(user.getIdToken()).pipe(
        map((idToken) => new User(user.email, user.uid, user.displayName, idToken, null))
      );
    })
  );

  constructor(private afAuth: AngularFireAuth) {
  }

  login(email: string, password: string): Observable<any> {
    return defer(() => this.afAuth.signInWithEmailAndPassword(email, password));
  }

  logout(): Observable<any> {
    return defer(() => this.afAuth.signOut());
  }

  getUser(): Observable<User> {
    return this.user;
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;

  @Input()
  dropDownItems = [];

  @Input()
  dropDownFirstItem = '';

  // @Output("displayByKeySelected") displayByKeySelected = new EventEmitter<string>();
  @Output() displayByKeySelected = new EventEmitter<string>();

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        backgroundColor: 'transparent',
        borderColor: '#ef4b24',
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        pointHoverBorderColor: 'transparent',
        fill: 'transparent',
        borderWidth: 6
      },
    ],
    labels: ['', '', '', '', '', '', '']
  };


  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.2
      }
    },
    scales: {
      x: {
        display: false,
      },
      'y-axis-0':
      {
        display: false
      },
    },
    plugins: {
      legend: { display: false },
    }
  };

  constructor() {}

  @Input()
  title: string;

  @Input()
  subtitle: string;

  @Input()
  text: string;

  @Input()
  welcome: boolean;

  @Input()
  wide: boolean;

  @Input()
  actionButton: string;

  @Input()
  subtext: string;

  @Input()
  loading = true;

  @Input()
  error: object;

  @Input()
  chartData: Array<number> = null;

  ngOnInit(): void {
    this.lineChartData.datasets[0].data = this.chartData;
  }

  displayText(displayByKey): any {
    this.displayByKeySelected.emit(displayByKey.value);
    this.dropDownFirstItem = displayByKey.key;
  }

}

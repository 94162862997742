
<div class="message-container" *ngIf="show">
    <div class="detail-row">
        <div class="label">{{title}}</div>
        <div class="val highlight" (click)="close()">X</div>
    </div>
    <div class="detail-row">
        <p class="small">{{message}}</p>
    </div>
    <div class="detail-row">
        <div class="label"></div>
        <div class="val highlight decorate">Notify Driver</div> 
    </div>
</div>   

import { Component, OnInit } from '@angular/core';
import { PaymentEnter, PaymentActivity } from './payment.model';
import { PaymentService } from './payment.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: [ './payment.component.scss', '../app.shared.scss' ]
})

export class PaymentComponent implements OnInit {
  loadedPaymentEnter: PaymentEnter = null;
  fetchingPaymentEnter: boolean = false;

  loadedPaymentActivity: PaymentActivity[];
  fetchingPaymentActivity: boolean = false;

  dataTableColumns = ['Ride ID', 'Date', 'Driver', 'Driver Payment', 'Ride Cost', 'Earnings'];
  dropdownFirstItem = 'Ride ID         ';
  dropdownMenu = {
    Date: 'date',
    Driver: 'driverLastName',
    'Ride ID': 'rideId'
  };

  sortByItem;

  constructor(private paymentService: PaymentService) {
  }

  ngOnInit(): void {
    this.fetchPaymentEnter();
    this.fetchPaymentActivity();
  }

  private fetchPaymentEnter(): void {
    this.fetchingPaymentEnter = true;
    this.paymentService.fetchPaymentEnter().subscribe(paymentEnter => {
      this.fetchingPaymentEnter = false;
      this.loadedPaymentEnter = paymentEnter;
    });
  }

  private fetchPaymentActivity(): void {
    this.fetchingPaymentActivity = true;
    this.paymentService.fetchPaymentActivity().subscribe(paymentActivity => {
      this.fetchingPaymentActivity = false;
      this.loadedPaymentActivity = paymentActivity;
    });
  }
}

import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { DriversEnter, DriversActivity } from './drivers.model';
import { DriversService } from './drivers.service';


@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: [ './drivers.component.scss', '../app.shared.scss' ]
})

export class DriversComponent implements OnInit {
  loadedDriversEnter: DriversEnter = null;
  fetchingDriversEnter: boolean = false;

  loadedDriversActivity: DriversActivity[];
  fetchingDriversActivity: boolean = false;

  dataTableColumns =  ['Driver ID', 'Name', 'No. Of Trips', 'Next Payment', 'Status', 'Ratings'];

  dropdownFirstItem = 'Driver ID       ';
  dropdownMenu = {
    Name: 'name',
    'No. of Trips': 'numberOfTrips',
    Status: 'status',
    Rating: 'ratings',
    ID: 'driverId'
  };

  sortByItem;


  displayDriverDetails: boolean = false;

  constructor(private driversService: DriversService) {
  }

  ngOnInit(): void {
    this.fetchDriversEnter();
    this.fetchDriversActivity();
  }

  private fetchDriversEnter(): void {
    this.fetchingDriversEnter = true;
    this.driversService.fetchDriversEnter().subscribe(driversEnter => {
      this.fetchingDriversEnter = false;
      this.loadedDriversEnter = driversEnter;
    });
  }

  private fetchDriversActivity(): void {
    this.fetchingDriversActivity = true;
    this.driversService.fetchDriversActivity().subscribe(driversActivity => {
      this.fetchingDriversActivity = false;
      this.loadedDriversActivity = driversActivity;
    });
  }

  closePopOver(): void {
    this.displayDriverDetails = false;
  }

  onSelectDriverProfileDetails(): void {
    this.displayDriverDetails = true;
    this.fetchingDriversActivity = true;
    this.driversService.fetchDriversActivity().subscribe(driversActivity => {
      this.fetchingDriversActivity = false;
      this.loadedDriversActivity = driversActivity;
    });
  }
}


<div class="driver-detail-container" [class.is-open]="show">
    <div (click)="close()" style="margin-top: 5%;"><img src="../../assets/img/global/collapsed-back-button.png"></div>
    <div class='driver-detail-header'>
      Driver Profile</div>
    <hr />
    <div class="detail-row">
      <div class="label" style="padding-top: 2%;">Driver Name</div> 
      <div class="val highlight big">Marcus Goodloe</div>
    </div>
    <div class="detail-row" style="color: #989898; font-size: 17px;">
      <div class="label">Join Date</div>
      <div class="val">February 12, 2021</div>
    </div>
    <hr />
    <div class="detail-row">
      <div class="label">Background Check</div>
      <div class="val highlight">Completed</div>
    </div>
    <div class="detail-row" style="color: #989898;">
      <div class="label small"></div>
      <div class="val small">February 11, 2021</div>
    </div>
    <hr />
    <div class="detail-row">
      <div class="label">Contact Info</div>
      <div class="val"> </div>
    </div>
    <br />
    <div class="detail-row">
      <div class="label small">Phone Number</div>
      <div class="val highlight small">323-155-2482</div>
    </div>
    <div class="detail-row">
      <div class="label small">Email Address</div>
      <div class="val highlight small">M.Goodloe@gmail.com</div>
    </div>
    <hr />
    <div class="detail-row">
      <div class="label">Vehicle Info</div>
      <div></div>
    </div>
    <br />
    <div class="detail-row">
      <div class="label small">License Number</div>
      <div class="val highlight small">B9917063   Exp. 06/25</div>
    </div>
    <div class="detail-row">
      <div class="label small">License Plate</div>
      <div class="val highlight small">5WYD078</div>
    </div>
    <hr />
    <div class="detail-row">
      <div class="label">Trip History</div>
      <div class="val highlight small pointer" style="padding-right:35px;" (click)="onClickViewTripHistory()">View</div> 
    </div>
    <hr />
    <div class="detail-row">
      <div class="label">Proof of Insurance</div>
      <div class="val highlight small pointer" style="padding-right:35px;" (click)="onClickViewInsurance()">View</div>
    </div>
    <hr />
    <div class="detail-row">
      <div class="label" style="flex: 1 0 50%;">Vehicle Registration</div>
      <div class="val highlight small pointer" style="padding-right:35px; flex: 1 0 50%;" (click)="onClickViewVehicleRegistration()">View</div> 
    </div>
    <hr />
    <div class="detail-row">
      <div class="label">Linked Account</div>
      <div class="val small"><fa-icon [icon]="faCcMastercard" size="2x"></fa-icon> *** *** *** 0122</div>
    </div>
    <br />
    <button class="delete-btn">Delete Driver</button>
    
  </div> 

  <app-driver-proof-of-insurance-popover [show]="displayInsuranceProof" (closePopup)="closePopOver()"></app-driver-proof-of-insurance-popover> 
  <app-driver-vehicle-registration-popover [show]="displayVehicleRegistration" (closePopup)="closePopOver()"></app-driver-vehicle-registration-popover> 
  <app-driver-trip-history-popover [show]="displayTripHistory" (closePopup)="closePopOver()"></app-driver-trip-history-popover> 
  
  

  



import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UsersEnter, UsersActivity, UserDetail } from './users.model';
import { RestEndpoints } from '../../globals/url-endpoint-globals';

@Injectable({ providedIn: 'root' })

export class UsersService {
    constructor(private http: HttpClient) {
    }

    fetchUsersEnter(): Observable<UsersEnter> {
        return this.http.get<UsersEnter>(RestEndpoints.usersEnterURL);
    }

    fetchUsersActivity(): Observable<UsersActivity> {
        return this.http.get<UsersActivity>(RestEndpoints.usersActivityURL);
    }

    getUserDetail(uid: string): Observable<UserDetail> {
        return this.http.get<UserDetail>(`${RestEndpoints.userDetailURL}/${uid}`);
    }
}

<div class="header-label">Orders</div>

<div class="small-bubbles-grid">
  <app-card title="Active Orders" [loading]="fetchingOrdersEnter" [text]="loadedOrdersEnter?.activeOrders" [chartData]="[35, 59, 51, 76, 60, 75]"></app-card>
  <app-card title="Total Orders" [loading]="fetchingOrdersEnter" [text]="loadedOrdersEnter?.totalOrders" [chartData]="[35, 59, 51, 76, 60, 75]"></app-card>
  <app-card title="Total Earnings" [loading]="fetchingOrdersEnter" [text]="loadedOrdersEnter?.totalEarnings"></app-card>
</div>

<app-datatable title="Recent Activity" [columns]="dataTableColumns" [loading]="fetchingOrdersActivity"
  [dataRows]="loadedOrdersActivity" [dropDownFirstItem]="dropdownFirstItem" [dropDownItems]="dropdownMenu" (sortByItemSelected)="sortByItem=$event">
 
  <div class="datatable-row" *ngFor="let ordersActivity of loadedOrdersActivity | sortBy:'asc': sortByItem"
  (click)="onSelectRecentActivityDetails(ordersActivity.id)">

    <div class="datatable-row-grid">
      <div class="status">
        <img src="../../assets/img/global/scheduled.png" *ngIf="ordersActivity.status == 1">
        <img src="../../assets/img/global/in-transit.png" *ngIf="ordersActivity.status == 2">
        <img src="../../assets/img/global/delivered.png" *ngIf="ordersActivity.status == 3">
      </div>

      <div class="datatable-row-field">#{{ ordersActivity.id }}</div>

      <div class="datatable-row-field">
        <div class="marker-pickup"><img src="../../assets/img/global/map-marker-pickup.png" width="32"
            height="35"></div>
        <div class="marker-dropoff"><img src="../../assets/img/global/map-marker-dropoff2.png" width="16"
            height="20"></div>
      </div>

      <div class="datatable-row-field">
        <div>{{ ordersActivity.addressPickupStreet }}</div>
        <div>{{ ordersActivity.addressDropoffStreet }}</div>
      </div>

      <div class="datatable-row-field">{{ ordersActivity.rideCost |
        currency:'USD' }}</div>
      <div class="datatable-row-field">{{ ordersActivity.earnings |
        currency:'USD' }}</div>
      <div class="datatable-row-field">{{ ordersActivity.date }}</div>
    </div>
  </div>
</app-datatable>


<app-order-detail-popover [show]="displayOrderDetails" class="orders-popover"
  [tripRating]="tripRating"
  (closePopup)="closePopOver()"
  [loadedOrderDetails]="loadedOrderDetails"
  [fetchingOrderDetails]="fetchingOrderDetails"
  [displayOrderDetailsTotal]="displayOrderDetailsTotal"></app-order-detail-popover> 
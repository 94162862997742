<img src="../../assets/img/auth/entrega-logo-and-text.png" class="auth-logo"/>
<div class="email-verification-card">
    <div class="email-verification-card-content">
     <h1 class="header">Verify your Email</h1>
        <p class="verification-text">We've just sent you an email to confirm your account.</p>
        <p class="verification-text">Please verify this email to complete sign up</p>
        <button 
        class="back-to-login-button" (click)="backToLogin()">Back to login</button>
        <p class="no-email-text">Didn't receive an email? <a [routerLink]=""><span class="underline" style="color:#FD0000"><u>Resend Email</u></span></a></p>
    </div>    
</div>

  import { Component } from '@angular/core';
  import { Subscription } from 'rxjs';
  import { AuthService } from './auth/auth.service';

  @Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [ './app.component.scss' ]
  })

  export class AppComponent {
    title = 'Entrega Dashboard';
    private userSub: Subscription;

    constructor(private authService: AuthService) {
    }

    onLogout(): void {
      this.authService.logout();
    }
  }

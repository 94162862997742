import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
    DashboardEnter,
    DashboardEarnings,
    DashboardDriverPayments,
    DashboardActivity,
    DashboardOrderDetails
} from './dashboard.model';
import { RestEndpoints } from '../../globals/url-endpoint-globals';

@Injectable({providedIn: 'root'})

export class DashboardService {
    constructor(private http: HttpClient) {
    }

    fetchDashboardEnter(): Observable<DashboardEnter> {
        return this.http.get<DashboardEnter>(RestEndpoints.dashboardEnterURL);
    }

    fetchEarnings(): Observable<DashboardEarnings> {
        return this.http.get<DashboardEarnings>(RestEndpoints.dashboardEarningsURL);
    }

    fetchDriverPayments(): Observable<DashboardDriverPayments> {
        return this.http.get<DashboardDriverPayments>(RestEndpoints.dashboardDriverPaymentsURL);
    }

    fetchDashboardActivity(): Observable<DashboardActivity[]> {
        return this.http.get<DashboardActivity>(RestEndpoints.dashboardActivityURL)
            .pipe(
                map(responseData => {
                    const dashboardActivityArray: DashboardActivity[] = [];

                    for (const key in responseData) {
                        if (responseData.hasOwnProperty(key)) {
                            dashboardActivityArray.push(responseData[key]);
                        }
                    }

                    return dashboardActivityArray;
                }));
    }

    fetchDashboardOrderDetails(id: number): Observable<DashboardOrderDetails> {
        return this.http.get<DashboardOrderDetails>(RestEndpoints.dashboardOrderDetailsURL);
    }
}

<div class="header-label">Earnings</div>

<div class="top-grid">
  
  <div class="action-grid">
    <app-card title="Total Earnings" [loading]="fetchingEarningsEnter"
      [text]="loadedEarningsEnter?.totalEarnings | currency:'USD'"
      [subtext]="!fetchingEarningsEnter && (loadedEarningsEnter != null) ? loadedEarningsEnter?.totalEarningsPeriod : '-'"
      actionButton='Deposit' [wide]="true"></app-card>
  </div>

  <div class="small-bubbles-grid">
    <app-card title="Last Month" [loading]="fetchingEarningsEnter"
      [text]="loadedEarningsEnter?.lastMonthEarnings | currency:'USD'"></app-card>
    <app-card title="Total Orders" [loading]="fetchingEarningsEnter" [text]="loadedEarningsEnter?.totalOrders" [chartData]="[35, 59, 51, 76, 60, 75]">
    </app-card>
  </div>

</div>


<app-datatable title="Driver Profiles" [columns]="dataTableColumns" [loading]="fetchingEarningsActivity"
  [dataRows]="loadedEarningsActivity"
  [dropDownFirstItem]="dropdownFirstItem"
  [dropDownItems]="dropdownMenu"
  (sortByItemSelected)="sortByItem=$event">
  
  <div class="datatable-row" *ngFor="let rowField of loadedEarningsActivity | sortBy:'desc': sortByItem"> 

    <div class="datatable-row-grid">
      <div class="datatable-row-field">#{{ rowField.rideId }}</div>
      <div class="datatable-row-field">#{{ rowField.date }}</div>
      <div class="datatable-row-field">{{ rowField.customerFirstName }} {{ rowField.customerLastName }}</div>
      <div class="datatable-row-field">{{ rowField.customerFirstName }} {{ rowField.customerLastName }}</div>
      <div class="datatable-row-field">{{ rowField.rideCost | currency:'USD' }}</div>
      <div class="datatable-row-field">{{ rowField.driverPayment | currency:'USD' }}</div>
      <div class="datatable-row-field">{{ rowField.earnings | currency:'USD' }}</div>
    </div>
    
  </div>

</app-datatable>



<div class="proof-of-insurance-container" [class.is-open]="show">
    <div (click)="close()" style="margin-top: 5%;"><img src="../../assets/img/global/collapsed-back-button.png"></div> 
    <div class='proof-of-insurance-header'>
      Proof of Insurance</div>
    <hr />

    <app-driver-expiration-notice-popover [show]="displayExpiredNotice" (closePopup)="closePopOver()"></app-driver-expiration-notice-popover> 

    <div class="detail-row">
      <div class="label" style="padding-top: 2%;">Insurance Carrier</div>
      <div class="val highlight big">State Farm</div>
    </div>
    <div class="detail-row">
      <div class="label">Policy Number</div>
      <div class="val highlight">PP281Y4GTW</div>
    </div>
    <div class="detail-row">
      <div class="label">Effective Date</div>
      <div class="val highlight"> 08/24/2019</div>
    </div>
    <div class="detail-row">
      <div class="label">Expiration Date</div>
      <div class="val highlight">08/24/2025</div>
    </div>
    <br /> 

    <div class="detail-row">
        <img class="insurance-card" src="../../assets/img/drivers/insurance-card-example.png" (click)="onClickInsuranceImage()">
    </div>

    
    <div class="detail-row">
        <div class="attachments">View Attachments</div>
    </div>
     
    
    
  </div> 





   
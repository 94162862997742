import { environment } from 'src/environments/environment';

export class RestEndpoints {

    public static dashboardEnterURL: string = 'assets/data/local/dashboard/dashboard-enter.json';
    // public static dashboardEnterURL: string = RestEndpoints.entegraDomainAndPort + "/dashboard";
    public static dashboardEarningsURL: string = 'assets/data/local/dashboard/dashboard-earnings.json';
    // public static dashboardEarningsURL: string = RestEndpoints.entegraDomainAndPort + "/earnings?range=month";
    public static dashboardDriverPaymentsURL: string = 'assets/data/local/dashboard/dashboard-driver-payments.json';
    public static dashboardActivityURL: string = 'assets/data/local/dashboard/dashboard-activity.json';
    public static dashboardOrderDetailsURL: string = 'assets/data/local/dashboard/dashboard-order-details.json';

    public static ordersEnterURL: string = 'assets/data/local/orders/orders-enter.json';
    public static ordersActivityURL: string = 'assets/data/local/orders/orders-activity.json';
    public static orderDetailsURL: string = 'assets/data/local/orders/order-details.json';

    public static driversEnterURL: string = 'assets/data/local/drivers/drivers-enter.json';
    public static driversActiveTripsURL: string = 'assets/data/local/drivers/drivers-active-trips.json';
    public static driversTotalDriversURL: string = 'assets/data/local/drivers/drivers-total-drivers.json';
    public static driversActivityURL: string = 'assets/data/local/drivers/drivers-activity.json';

    public static usersEnterURL: string = 'assets/data/local/users/users-enter.json';
    public static usersActivityURL: string = `${environment.entregaDomainAndPort}/users?sort=joinDate`;
    public static userDetailURL: string = `${environment.entregaDomainAndPort}/user`;

    public static earningsEnterURL: string = 'assets/data/local/earnings/earnings-enter.json';
    public static earningsActivityURL: string = 'assets/data/local/earnings/earnings-activity.json';

    public static paymentEnterURL: string = 'assets/data/local/payment/payment-enter.json';
    public static paymentActivityURL: string = 'assets/data/local/payment/payment-activity.json';
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-driver-expiration-notice-popover',
  templateUrl: './driver-expiration-notice-popover.component.html',
  styleUrls: ['./driver-expiration-notice-popover.component.scss']
})
export class DriverExpirationNoticePopoverComponent implements OnInit {

  @Input()
  title = 'Expired Insurance';

  @Input()
  message = 'Driver insurance is past the expiration date. This driver will become inactive until insurance is updated.';

  @Input()
  show = false;

  @Output()
  closePopup: EventEmitter<any> = new EventEmitter();

  close(): void {
    this.closePopup.emit();
  }

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: [ './auth.component.scss' ]
})
export class AuthComponent {
  isLoading = false;
  error: string = null;

  constructor(private router: Router, private authService: AuthService) {
  }

  onSubmit(form: NgForm): void {
    if (!form.valid) {
      return;
    }

    const email = form.value.email;
    const password = form.value.password;

    this.isLoading = true;

    this.authService.login(email, password).subscribe(() => {
      this.isLoading = false;
      this.router.navigate(['dashboard']);
    }, errorMessage => {
      this.isLoading = false;
      this.error = errorMessage;
    });

    form.reset();
  }
  signUp(): void {
    this.router.navigate(['signup']);
  }
}

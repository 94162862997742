import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { OrdersEnter, OrdersActivity, OrderDetails } from './orders.model';
import { RestEndpoints } from '../../globals/url-endpoint-globals';

@Injectable({providedIn: 'root'})

export class OrdersService {
    constructor(private http: HttpClient) {
    }

    fetchOrdersEnter(): Observable<OrdersEnter> {
        return this.http.get<OrdersEnter>(RestEndpoints.ordersEnterURL);
    }

    fetchOrdersActivity(): Observable<OrdersActivity[]> {
        return this.http.get<OrdersActivity>(RestEndpoints.ordersActivityURL)
            .pipe(
                map(responseData => {
                    const ordersActivityArray: OrdersActivity[] = [];

                    for (const key in responseData) {
                        if (responseData.hasOwnProperty(key)) {
                            ordersActivityArray.push(responseData[key]);
                        }
                    }

                    return ordersActivityArray;
                }));
    }

    fetchOrderDetails(id: number): Observable<OrderDetails> {
        return this.http.get<OrderDetails>(`${RestEndpoints.orderDetailsURL}?id=${id}`);
    }
}

import { AuthService } from '../auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { UploaderService } from '../services/uploader.service';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { User } from '../auth/user.model';
import { UsersService } from '../users/users.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes,
} from '@angular/animations';
import { UserDetail } from '../users/users.model';


@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss'],
  animations: [
    trigger('uploadDone', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]
      ),
      transition(':leave', [
        animate('500ms', style({ opacity: 0 }))
      ])
    ]
    )]
})

export class AdminProfileComponent implements OnInit {

  constructor(private uploader: UploaderService, private authService: AuthService, private usersService: UsersService) { }

  hasCardsInfo: boolean;

  showTeamInfo: boolean = true;

  faInfoCircle = faInfoCircle;
  progress: number;
  infoMessage: any;
  isUploading: boolean = false;
  faPencilAlt = faPencilAlt;

  user: User;

  userDetail: UserDetail;

  imageUrl: string | ArrayBuffer =
    '/assets/img/global/profile-image.jpg';

  file: File;

  ngOnInit(): void {

    this.uploader.progressSource.subscribe(progress => {
      this.progress = progress;
    });


    this.authService.getUser().subscribe(user => {
      this.user = user;
      this.usersService.getUserDetail(this.user.id).subscribe(detail => {
        this.userDetail = detail;
        if (this.userDetail?.photoPath && this.userDetail?.photoPath !== '') {
          this.imageUrl = this.userDetail.photoPath;
        }
      });
    });

  }


  toggleTeamInfo(): void {
    this.showTeamInfo = !this.showTeamInfo;
  }

  onFileChanged(file: File): void {

    if (file) {
      this.file = file;
      if (this.upload()) {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = event => {
          this.imageUrl = reader.result;
        };
      }
    }
  }

  upload(): boolean {
    this.infoMessage = null;
    this.progress = 0;
    this.isUploading = true;

    this.uploader.upload(this.user.id, this.user.token, this.file).subscribe(message => {

      this.isUploading = false;
      this.infoMessage = message;

      if (message.indexOf('error') > -1) {
        console.log('error uploading then set profile picture to old one');
        return false;
      }

      setTimeout(() => {
        this.infoMessage = false;
      }, 2500);

    });

    return true;
  }


}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule, PERSISTENCE } from '@angular/fire/auth';
import { canActivate, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';

import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { DashboardContainerComponent } from './dashboard-container/dashboard.container.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OrdersComponent } from './orders/orders.component';
import { DriversComponent } from './drivers/drivers.component';
import { UsersComponent } from './users/users.component';
import { EarningsComponent } from './earnings/earnings.component';
import { PaymentComponent } from './payment/payment.component';

import { environment } from 'src/environments/environment';
import { NavigatorComponent } from './dashboard-container/navigator/navigator.component';
import { NavigatorItemComponent } from './dashboard-container/navigator-item/navigator-item.component';
import { CardComponent } from './card/card.component';
import { DatatableComponent } from './datatable/datatable.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OrderDetailPopoverComponent } from './order-detail-popover/order-detail-popover.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgChartsModule } from 'ng2-charts';
import { DriverDetailPopoverComponent } from './driver-detail-popover/driver-detail-popover.component';
import { UsersDropdownMenuComponent } from './users-dropdown-menu/users-dropdown-menu.component';
import { SortByPipe } from './pipes/sort-by.pipe';
import { AngularFittextModule } from 'angular-fittext';
import { DriverProofOfInsurancePopoverComponent } from './driver-proof-of-insurance-popover/driver-proof-of-insurance-popover.component';
import { DriverVehicleRegistrationPopoverComponent } from './driver-vehicle-registration-popover/driver-vehicle-registration-popover.component';
import { DriverTripHistoryPopoverComponent } from './driver-trip-history-popover/driver-trip-history-popover.component';
import { DriverExpirationNoticePopoverComponent } from './driver-expiration-notice-popover/driver-expiration-notice-popover.component';
import { UsersTripHistoryPopoverComponent } from './users-trip-history-popover/users-trip-history-popover.component';
import { PayoutSettingsComponent } from './payout-settings/payout-settings.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { AdminSignupComponent } from './admin-signup/admin-signup.component';
import { AdminEmailVerificationComponent } from './admin-email-verification/admin-email-verification.component';



const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['dashboard']);
const appRoutes: Routes = [
  { path: 'login', component: AuthComponent, ...canActivate(redirectLoggedInToDashboard) },
  { path: 'signup', component: AdminSignupComponent },
  { path: 'email-verification', component: AdminEmailVerificationComponent},
  {
    path: '', component: DashboardContainerComponent,
    children: [
      { path: 'profile', component: AdminProfileComponent, ...canActivate(redirectUnauthorizedToLogin) },
      { path: 'dashboard', component: DashboardComponent, ...canActivate(redirectUnauthorizedToLogin) },
      { path: 'orders', component: OrdersComponent, ...canActivate(redirectUnauthorizedToLogin) },
      { path: 'drivers', component: DriversComponent, ...canActivate(redirectUnauthorizedToLogin) },
      { path: 'users', component: UsersComponent, ...canActivate(redirectUnauthorizedToLogin) },
      { path: 'earnings', component: EarningsComponent, ...canActivate(redirectUnauthorizedToLogin) },
      { path: 'payment', component: PaymentComponent, ...canActivate(redirectUnauthorizedToLogin) },
      { path: '**', redirectTo: '/dashboard', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    LoadingSpinnerComponent,
    DashboardContainerComponent,
    DashboardComponent,
    OrdersComponent,
    DriversComponent,
    UsersComponent,
    EarningsComponent,
    PaymentComponent,
    NavigatorComponent,
    NavigatorItemComponent,
    CardComponent,
    DatatableComponent,
    OrderDetailPopoverComponent,
    DriverDetailPopoverComponent,
    UsersDropdownMenuComponent,
    SortByPipe,
    DriverProofOfInsurancePopoverComponent,
    DriverVehicleRegistrationPopoverComponent,
    DriverTripHistoryPopoverComponent,
    DriverExpirationNoticePopoverComponent,
    UsersTripHistoryPopoverComponent,
    PayoutSettingsComponent,
    AdminProfileComponent,
    AdminSignupComponent,
    AdminEmailVerificationComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    FontAwesomeModule,
    NgbModule,
    NgChartsModule,
    AngularFittextModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    { provide: PERSISTENCE, useValue: 'local' }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

<div class="dashboard-navigator">
    <div class="navigator-logo">
        <div class="dashboard-navigator-title-image"><img src="../../assets/img/dashboard/white-dove-sm.png"></div>
        <div class="dashboard-navigator-title-text"><img src="../../assets/img/dashboard/entrega-words-white-sm.png"></div>
    </div>

    <div class="navigator-container">
        <app-navigator-item img="../../assets/img/dashboard/dashboard.png" link="/dashboard" title="Dashboard">
        </app-navigator-item>
        <app-navigator-item img="../../assets/img/dashboard/orders.png" link="/orders" title="Orders">
        </app-navigator-item>
        <app-navigator-item img="../../assets/img/dashboard/drivers.png" link="/drivers" title="Drivers">
        </app-navigator-item>
        <app-navigator-item img="../../assets/img/dashboard/users.png" link="/users" title="Users"></app-navigator-item>
        <app-navigator-item img="../../assets/img/dashboard/earnings.png" link="/earnings" title="Earnings">
        </app-navigator-item>
        <app-navigator-item img="../../assets/img/dashboard/payment.png" link="/payment" title="Payment">
        </app-navigator-item>
    </div>

    <div class="dashboard-navigator-logout">
        <a (click)="logout()">Logout</a>
    </div>
</div>
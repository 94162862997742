
<div class="header-label">Drivers</div>

<div class="top-grid">

  <div class="action-grid">
    <app-card title="Total Payment" [loading]="fetchingDriversEnter"
      [text]="loadedDriversEnter?.totalPayment | currency:'USD'"
      [subtext]="!fetchingDriversEnter && (loadedDriversEnter != null) ? 'Due ' + loadedDriversEnter?.totalPaymentDueDate : '-'"
      actionButton='Pay Now' [wide]="true"></app-card>
  </div>

  <div class="small-bubbles-grid">
    <app-card title="Active Trips" [loading]="fetchingDriversEnter" [text]="loadedDriversEnter?.activeTrips" [chartData]="[40, 59, 51, 76, 30, 75]"></app-card>
    <app-card title="Total Drivers" [loading]="fetchingDriversEnter" [text]="loadedDriversEnter?.totalDrivers" [chartData]="[35, 59, 21, 76, 50, 75]">
    </app-card>
  </div>
  
</div>


<app-datatable title="Driver Profiles" [columns]="dataTableColumns" [loading]="fetchingDriversActivity"
  [dataRows]="loadedDriversActivity"
  [dropDownFirstItem]="dropdownFirstItem"
  [dropDownItems]="dropdownMenu"
  (sortByItemSelected)="sortByItem=$event">
  
  <div class="datatable-row" *ngFor="let driverActivity of loadedDriversActivity | sortBy:'asc': sortByItem;" (click)="onSelectDriverProfileDetails()"> 

    <div class="datatable-row-grid">
      <div class="datatable-row-field">#{{ driverActivity.id }}</div>
      <div class="datatable-row-field">#{{ driverActivity.name }}</div>
      <div class="datatable-row-field">{{ driverActivity.numberOfTrips }}</div>
      <div class="datatable-row-field">{{ driverActivity.nextPaymentAmount }}</div>
      <div class="datatable-row-field">
        <img src="../../assets/img/drivers/offline.png" *ngIf="driverActivity.status == 1">
        <img src="../../assets/img/drivers/online.png" *ngIf="driverActivity.status == 2"> 
      </div>
      <div class="datatable-row-field">{{ driverActivity.ratings }}</div>
    </div>
  </div>

</app-datatable>

  <app-driver-detail-popover [show]="displayDriverDetails"
  (closePopup)="closePopOver()"></app-driver-detail-popover>  

  


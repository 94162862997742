import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-signup',
  templateUrl: './admin-signup.component.html',
  styleUrls: ['./admin-signup.component.scss']
})
export class AdminSignupComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  getStarted() {
    this.router.navigate(['dashboard']);
  }
  backToLogin() {
    this.router.navigate(['login']);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaymentEnter, PaymentActivity } from './payment.model';
import { RestEndpoints } from '../../globals/url-endpoint-globals';

@Injectable({providedIn: 'root'})

export class PaymentService {
    constructor(private http: HttpClient) {
    }

    fetchPaymentEnter(): Observable<PaymentEnter> {
        return this.http.get<PaymentEnter>(RestEndpoints.paymentEnterURL);
    }

    fetchPaymentActivity(): Observable<PaymentActivity[]> {
        return this.http.get<PaymentActivity>(RestEndpoints.paymentActivityURL)
            .pipe(
                map(responseData => {
                    const paymentActivityArray: PaymentActivity[] = [];

                    for (const key in responseData) {
                        if (responseData.hasOwnProperty(key)) {
                            paymentActivityArray.push(responseData[key]);
                        }
                    }

                    return paymentActivityArray;
                }));
    }
}

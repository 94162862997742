import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-driver-trip-history-popover',
  templateUrl: './driver-trip-history-popover.component.html',
  styleUrls: ['./driver-trip-history-popover.component.scss']
})
export class DriverTripHistoryPopoverComponent implements OnInit {

  @Input()
  show = false;

  @Output()
  closePopup: EventEmitter<any> = new EventEmitter();

  close(): void {
    this.closePopup.emit();
  }

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { UsersEnter, UsersActivity } from './users.model';
import { UsersService } from './users.service';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss', '../app.shared.scss']
})

export class UsersComponent implements OnInit {
  loadedUsersEnter: UsersEnter = null;
  fetchingUsersEnter: boolean = false;

  loadedUsersActivity: UsersActivity[];
  fetchingUsersActivity: boolean = false;
  selectedUserIndex = 0;

  displayTripHistory: boolean = false;

  dataTableColumns = ['ID', 'Name', 'Email', 'City', 'Join Date', 'Other Action'];
  dropdownFirstItem = 'Name';

  dropdownMenu = {
    Name: 'firstName',
    Email: 'emailAddress',
    City: 'city',
    'Join Date': 'createdAt'
  };

  faCoffee = faCoffee;
  faCcMastercard = faCcMastercard;

  sortByItem;

  constructor(private usersService: UsersService) {
  }

  ngOnInit(): void {
    this.fetchUsersEnter();
    this.fetchUsersActivity();
  }

  private fetchUsersEnter(): void {
    this.fetchingUsersEnter = true;
    this.usersService.fetchUsersEnter().subscribe(usersEnter => {
      this.fetchingUsersEnter = false;
      this.loadedUsersEnter = usersEnter;
    });
  }

  private fetchUsersActivity(): void {
    this.fetchingUsersActivity = true;
    this.usersService.fetchUsersActivity().subscribe(usersActivity => {
      this.fetchingUsersActivity = false;
      this.loadedUsersActivity = usersActivity.items;
    });
  }

  onClickTripHistoryBtn(userIndex): void {
    this.selectedUserIndex = userIndex;
    this.displayTripHistory = true;
  }

  closePopOver(): void {
    this.displayTripHistory = false;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EarningsEnter, EarningsActivity } from './earnings.model';
import { RestEndpoints } from '../../globals/url-endpoint-globals';

@Injectable({providedIn: 'root'})

export class EarningsService {
    constructor(private http: HttpClient) {
    }

    fetchEarningsEnter(): Observable<EarningsEnter> {
        return this.http.get<EarningsEnter>(RestEndpoints.earningsEnterURL);
    }

    fetchEarningsActivity(): Observable<EarningsActivity[]> {
        return this.http.get<EarningsActivity>(RestEndpoints.earningsActivityURL)
            .pipe(
                map(responseData => {
                    const earningsActivityArray: EarningsActivity[] = [];

                    for (const key in responseData) {
                        if (responseData.hasOwnProperty(key)) {
                            earningsActivityArray.push(responseData[key]);
                        }
                    }

                    return earningsActivityArray;
                }));
    }
}

<div class="main-card" *ngIf="!wide; else widecard">
    <div [class.welcome-card]="welcome" [class.card-small]="!welcome">

        <div [class.card-container]="!welcome" [class.welcome-card-container]="welcome">

            <div class="card-content"  *ngIf="!welcome; else welcomecard">
                
                <div [class.card-text]="chartData" [class.card-text-only]="!chartData">
                        <ng-template [ngIf]="error">
                            {{ error }}
                        </ng-template>
                        <ng-template [ngIf]="!error && loading">
                            Loading...
                        </ng-template>
                        <ng-template [ngIf]="!error && !loading && (text === '')">
                            ?
                        </ng-template>
                        <ng-template [ngIf]="!error && !loading && (text !== '')">
                            {{ text }}
                        </ng-template>
                </div>
                <div class="graph-arrow up" *ngIf="chartData">
                    <fa-icon [icon]="faCaretUp"></fa-icon>
                </div>
                <div class="line-chart" *ngIf="chartData">
                    <canvas baseChart
                    [data]="lineChartData"
                    [options]="lineChartOptions"
                    [type]="'line'" >
                    </canvas>
                </div>


            </div>

            <div class="card-label" *ngIf="!welcome">
                {{ title }}
            </div>

            <ng-template #welcomecard>

                <div [class.welcome-card-label]="welcome" [class.card-label]="!welcome">
                    {{ title }}
                </div>

                    <div class="welcome-card-content" >
                        <ng-template [ngIf]="error">
                            {{ error }}
                        </ng-template>
                        <ng-template [ngIf]="!error && loading">
                            Loading...
                        </ng-template>
                        <ng-template [ngIf]="!error && !loading && (text === '')">
                            ?
                        </ng-template>
                        <ng-template [ngIf]="!error && !loading && (text !== '')">
                            {{ text }}
                        </ng-template>
                    </div>
        
                  

                    <div *ngIf="welcome">
                        <div class="welcome-card-text">Entrega Dashboard</div>
                    </div>

            </ng-template>

         
        </div>
        

    </div>
</div>

<ng-template #widecard>

    <div *ngIf="!actionButton && !subtext; else actioncard">
        <div class="wide-card">
            <div class="wide-card-title">{{ title }}</div> 

            <div class="row">
                <div class="col">
                    <div ngbDropdown>
                        <button class="btn btn-dropdown-menu" ngbDropdownToggle>{{dropDownFirstItem}}</button>
                        <div id="dropdownMenu" ngbDropdownMenu>
                            <button class="btn-dropdown-item" ngbDropdownItem *ngFor="let dropDownItem of dropDownItems| keyvalue" (click)="displayText(dropDownItem)">{{ dropDownItem.key }} </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="wide-card-subtitle">{{ subtitle }}</div>      

            <div class="wide-card-container">
                <div class="wide-card-text" fittext>
                        <ng-template [ngIf]="error">
                            {{ error }}
                        </ng-template>
                        <ng-template [ngIf]="!error && loading">
                            Loading...
                        </ng-template>
                        <ng-template [ngIf]="!error && !loading && (text === '')">
                            ?
                        </ng-template>
                        <ng-template [ngIf]="!error && !loading && (text !== '')"> 
                            {{ text }}
                        </ng-template>
                </div>
            </div>

        </div>
    </div>

    <ng-template #actioncard>
        <div class="action-card">
            <div class="wide-card-title">{{ title }}</div>

                <div class="action-card-container">
                    <div class="action-card-content">
                            <ng-template [ngIf]="error">
                                {{ error }}
                            </ng-template>
                            <ng-template [ngIf]="!error && loading">
                                Loading...
                            </ng-template>
                            <ng-template [ngIf]="!error && !loading && (text === '')">
                                ?
                            </ng-template>
                            <ng-template [ngIf]="!error && !loading && (text !== '')">
                                {{ text }}
                            </ng-template>
                    </div>
                    <div class="action-card-button">
                        <div class="action-button">
                            <div class="action-button-text">
                                {{ actionButton }}
                            </div>
                        </div>
                    </div>
                </div>
               

            <div class="action-card-subtext">
                {{ subtext }}
            </div>

        </div>
    </ng-template>


</ng-template>
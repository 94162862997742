<div class="header-label">Payments</div>

<div class="top-grid">
  
  <div class="action-grid">
    <app-card title="Total Expenses" [loading]="fetchingPaymentEnter"
      [text]="loadedPaymentEnter?.totalExpenses | currency:'USD'"
      [subtext]="!fetchingPaymentEnter && (loadedPaymentEnter != null) ? loadedPaymentEnter?.totalExpensesDueDate : '-'"
      actionButton='Pay Now' [wide]="true"></app-card>
  </div>

  <div class="small-bubbles-grid">
    <app-card title="Last Month" [loading]="fetchingPaymentEnter"
      [text]="loadedPaymentEnter?.lastMonthPayment | currency:'USD'"></app-card>
    <app-card title="Total Drivers" [loading]="fetchingPaymentEnter" [text]="loadedPaymentEnter?.totalDrivers"></app-card>
  </div>

</div>


<app-datatable title="Payment Report" [columns]="dataTableColumns" [loading]="fetchingPaymentActivity"
  [dataRows]="loadedPaymentActivity"
  [dropDownFirstItem]="dropdownFirstItem"
  [dropDownItems]="dropdownMenu"
  (sortByItemSelected)="sortByItem=$event">
 
  <div class="datatable-row" *ngFor="let rowField of loadedPaymentActivity | sortBy:'desc': sortByItem">

    <div class="datatable-row-grid">
      <div class="datatable-row-field">#{{ rowField.rideId }}</div>
      <div class="datatable-row-field">#{{ rowField.date }}</div>
      <div class="datatable-row-field">{{ rowField.driverFirstName }} {{ rowField.driverLastName }}</div>
      <div class="datatable-row-field">{{ rowField.driverPayment }}</div>
      <div class="datatable-row-field">{{ rowField.rideCost | currency:'USD' }}</div>
      <div class="datatable-row-field">{{ rowField.earnings | currency:'USD' }}</div>
    </div>
  </div>

</app-datatable> 


<div class="datatable-container">

  <div class="datatable-header">
    <div class="datatable-header-grid">
      <div class="datatable-header-title">{{title}}</div>  

        <div class="row">
          <div class="col">
              <div ngbDropdown>
                  <button class="btn btn-dropdown-menu" ngbDropdownToggle>{{dropDownFirstItem}}</button>
                  <div id="dropdownMenu" ngbDropdownMenu> 
                      <button class="btn-dropdown-item" ngbDropdownItem *ngFor="let dropDownItem of dropDownItems| keyvalue" (click)="sort(dropDownItem)">{{ dropDownItem.key }} </button>
                  </div>
              </div> 
          </div>

        <!-- <div class="dashboard-dropdown-list-chevron dashboard-dropdown-list-chevron-transform-down"></div> --> 
      </div>
      <div class="dashboard-dropdown-three-dots">&#x22EE;</div>
    </div>

    <div class="datatable-header-columns">
      <div class="datatable-column-title" *ngFor="let column of columns"> 
        {{ column }}
      </div>
    </div>
  </div>

  <div class="datatable-body">
    <div class="datatable-row-text" *ngIf="loading; else elseBlock">Loading...</div>
    <ng-template #elseBlock>
      <ng-content></ng-content>
    </ng-template>
  </div>

</div> 
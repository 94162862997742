<!-- <p (click)="close()" *ngIf="show">users-trip-history-popover works!</p> -->

<div class="trip-history-container" [class.is-open]="show">
    <div (click)="close()" style="margin-top: 5%;"><img src="../../assets/img/global/collapsed-back-button.png"></div>
    <div class="detail-row">
        <div class='trip-history-header label'>User Info</div>
        <div class="trip-history-header-two val">{{ userActivity.firstName}} {{ userActivity.lastName}}</div>
      </div>
    <hr />
    <div class="detail-row">
      <div class="label">Trip History</div>
    </div>

    <div class="trip-history-grid">
        <div class="grid-item history-labels">Date</div>  
        <div class="grid-item history-labels">Ride ID</div>
        <div class="grid-item history-labels">Cost</div>
        <div class="grid-item history-labels">Drop Address</div>
        <div class="grid-item item-value">03/25/2021</div>
        <div class="grid-item item-value">#01234</div>
        <div class="grid-item item-value">$100.00</div>
        <div class="grid-item item-value">1002 P. Sherman Wallaby Way</div> 
        <div class="grid-item item-value">03/20/2021</div>
        <div class="grid-item item-value">#01234</div>
        <div class="grid-item item-value">$100.00</div>
        <div class="grid-item item-value">1002 P. Sherman Wallaby Way</div>
        <div class="grid-item item-value">03/19/2021</div>
        <div class="grid-item item-value">#01234</div>
        <div class="grid-item item-value">$100.00</div>
        <div class="grid-item item-value">1002 P. Sherman Wallaby Way</div>
        <div class="grid-item item-value">03/18/2021</div>
        <div class="grid-item item-value">#01234</div>
        <div class="grid-item item-value">$100.00</div>
        <div class="grid-item item-value">1002 P. Sherman Wallaby Way</div>
        <div class="grid-item item-value">03/10/2021</div>
        <div class="grid-item item-value">#01234</div>
        <div class="grid-item item-value">$100.00</div>
        <div class="grid-item item-value">1002 P. Sherman Wallaby Way</div>
        <div class="grid-item item-value">03/25/2021</div>
        <div class="grid-item item-value">#01234</div>
        <div class="grid-item item-value">$100.00</div>
        <div class="grid-item item-value">1002 P. Sherman Wallaby Way</div>
        <div class="grid-item item-value">03/25/2021</div>
        <div class="grid-item item-value">#01234</div>
        <div class="grid-item item-value">$100.00</div>
        <div class="grid-item item-value">1002 P. Sherman Wallaby Way</div>
        <div class="grid-item item-value">03/25/2021</div>
        <div class="grid-item item-value">#01234</div>
        <div class="grid-item item-value">$100.00</div>
        <div class="grid-item item-value">1002 P. Sherman Wallaby Way</div>
        <div class="grid-item item-value">03/10/2021</div>
        <div class="grid-item item-value">#01234</div>
        <div class="grid-item item-value">$100.00</div>
        <div class="grid-item item-value">1002 P. Sherman Wallaby Way</div>
        <div class="grid-item item-value">03/25/2021</div>
        <div class="grid-item item-value">#01234</div>
        <div class="grid-item item-value">$100.00</div>
        <div class="grid-item item-value">1002 P. Sherman Wallaby Way</div>
        <div class="grid-item item-value">03/25/2021</div>
        <div class="grid-item item-value">#01234</div>
        <div class="grid-item item-value">$100.00</div>
        <div class="grid-item item-value">1002 P. Sherman Wallaby Way</div>
        <div class="grid-item item-value">03/25/2021</div>
        <div class="grid-item item-value">#01234</div>
        <div class="grid-item item-value">$100.00</div>
        <div class="grid-item item-value">1002 P. Sherman Wallaby Way</div>
    </div>
    <hr />
      
  </div>    



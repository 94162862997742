import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-email-verification',
  templateUrl: './admin-email-verification.component.html',
  styleUrls: ['./admin-email-verification.component.scss']
})
export class AdminEmailVerificationComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit(): void {
  }
  backToLogin() {
    this.router.navigate(['./admin-signup.component.html']);
  }  

}

import { Component, OnInit } from '@angular/core';
import { OrdersEnter, OrdersActivity, OrderDetails } from './orders.model';
import { OrdersService } from './orders.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: [ './orders.component.scss', '../app.shared.scss' ]
})

export class OrdersComponent implements OnInit {
  loadedOrdersEnter: OrdersEnter = null;
  fetchingOrdersEnter: boolean = false;

  loadedOrdersActivity: OrdersActivity[];
  fetchingOrdersActivity: boolean = false;

  displayOrderDetails: boolean = false;
  loadedOrderDetails: OrderDetails = null;
  fetchingOrderDetails: boolean = false;
  displayOrderDetailsTotal: number = 0;
  tripRating = 0;

  dataTableColumns = ['Status', 'Order ID', 'Pick/Drop Address', 'Ride Cost', 'Earnings', 'Date'];

  dropdownFirstItem = 'Order ID       ';
  dropdownMenu = {
    Status: 'status',
    'Order ID': 'id',
    Date: 'date'
  };

  sortByItem;
  constructor(private ordersService: OrdersService) {
  }

  ngOnInit(): void {
    this.fetchOrdersEnter();
    this.fetchOrdersActivity();
  }

  private fetchOrdersEnter(): void {
    this.fetchingOrdersEnter = true;
    this.ordersService.fetchOrdersEnter().subscribe(ordersEnter => {
      this.fetchingOrdersEnter = false;
      this.loadedOrdersEnter = ordersEnter;
    });
  }

  private fetchOrdersActivity(): void {
    this.fetchingOrdersActivity = true;
    this.ordersService.fetchOrdersActivity().subscribe(ordersActivity => {
      this.fetchingOrdersActivity = false;
      this.loadedOrdersActivity = ordersActivity;
    });
  }

  onSelectRecentActivityDetails(id: number): void {
    this.displayOrderDetails = true;
    this.fetchingOrderDetails = true;
    this.ordersService.fetchOrderDetails(id).subscribe( orderDetail => {
      this.fetchingOrderDetails = false;
      this.loadedOrderDetails = orderDetail;
      this.tripRating =  orderDetail.tripRating.valueOf();   // This sets the rating variable to the rating value from the database

      for (const item of this.loadedOrderDetails.tripItems)  {
        this.displayOrderDetailsTotal += item.quantity;
      }
    });
  }

  closePopOver(): void {
    this.displayOrderDetails = false;
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DriversEnter, DriversActivity } from './drivers.model';
import { RestEndpoints } from '../../globals/url-endpoint-globals';

@Injectable({providedIn: 'root'})

export class DriversService {
    constructor(private http: HttpClient) {
    }

    fetchDriversEnter(): Observable<DriversEnter> {
        return this.http.get<DriversEnter>(RestEndpoints.driversEnterURL);
    }

    fetchDriversActivity(): Observable<DriversActivity[]> {
        return this.http.get<DriversActivity>(RestEndpoints.driversActivityURL)
            .pipe(
                map(responseData => {
                    const driversActivityArray: DriversActivity[] = [];

                    for (const key in responseData) {
                        if (responseData.hasOwnProperty(key)) {
                            driversActivityArray.push(responseData[key]);
                        }
                    }

                    return driversActivityArray;
                }));
    }
}

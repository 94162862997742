import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UsersActivity } from '../users/users.model';

@Component({
  selector: 'app-users-trip-history-popover',
  templateUrl: './users-trip-history-popover.component.html',
  styleUrls: ['./users-trip-history-popover.component.scss']
})
export class UsersTripHistoryPopoverComponent implements OnInit {

  @Input() userActivity;
  // @Input() selectedUserIndex = 0;


  @Input()
  show = false;

  @Output()
  closePopup: EventEmitter<any> = new EventEmitter();

  close(): void {
    this.closePopup.emit();
  }

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { EarningsEnter, EarningsActivity } from './earnings.model';
import { EarningsService } from './earnings.service';

@Component({
  selector: 'app-earnings',
  templateUrl: './earnings.component.html',
  styleUrls: [ './earnings.component.scss', '../app.shared.scss' ]
})

export class EarningsComponent implements OnInit {
  loadedEarningsEnter: EarningsEnter = null;
  fetchingEarningsEnter: boolean = false;

  loadedEarningsActivity: EarningsActivity[];
  fetchingEarningsActivity: boolean = false;

  dataTableColumns = ['Ride ID', 'Date', 'Customer', 'Driver', 'Ride Cost', 'Driver Payment', 'Earnings'];

  dropdownFirstItem = 'Ride ID             ';
  dropdownMenu = {
    Date: 'date',
    Customer: 'customerFirstName',
    Driver: 'driverFirstName',
    'Ride ID': 'rideId'
  };

  sortByItem;

  constructor(private earningsService: EarningsService) {
  }

  ngOnInit(): void {
    this.fetchEarningsEnter();
    this.fetchEarningsActivity();
  }

  private fetchEarningsEnter(): void {
    this.fetchingEarningsEnter = true;
    this.earningsService.fetchEarningsEnter().subscribe(earningsEnter => {
      this.fetchingEarningsEnter = false;
      this.loadedEarningsEnter = earningsEnter;
    });
  }

  private fetchEarningsActivity(): void {
    this.fetchingEarningsActivity = true;
    this.earningsService.fetchEarningsActivity().subscribe(earningsActivity => {
      this.fetchingEarningsActivity = false;
      this.loadedEarningsActivity = earningsActivity;
    });
  }
}

<header>
    <div class="header-label">Profile</div>
</header>

<input #uploader type="file" (change)="onFileChanged(uploader.files[0])" style="display: none;" />


<div class="profile-top-container">
    <div class="profile-image-container">
       
        <ng-template [ngIf]="!isUploading">
            
            <div class="profile-image">
                <img (click)="uploader.click()" [src]="imageUrl" 
                width="160" height="160">
                <div class="pencil-icon">
                    <img src="/assets/icons/pencil-icon.svg">
                </div>
            </div>
        </ng-template>

        <ng-template [ngIf]="isUploading">
            <div class="profile-image spinning-border">
                <img class="spinning-loader" src="/assets/img/global/loader.svg" width=80 height=80 />
            </div>
            
        </ng-template>

        <div @uploadDone *ngIf="infoMessage" class="upload-message">{{infoMessage === 'Success' ? 'Image is uploaded
            successfully.': infoMessage}}</div>
    </div>

    <div class="admin-details-container">
        <div class="admin-label">EMAIL</div>
        <div class="admin-value">{{ userDetail?.user.emailAddress }} </div>
        <div class="admin-last-col edit-btn"><button>Edit</button></div>

        <div class="admin-label">NAME</div>
        <div class="admin-value">{{ userDetail?.user.firstName}} {{ userDetail?.user.lastName}}</div>
        <div class="admin-last-col"></div>

        <div class="admin-label">PASSWORD</div>
        <div class="admin-value">**********</div>
        <div class="admin-last-col"></div>
    </div>
</div>

<div class="profile-container">

    <div class="stripe-card">
        <div style="flex: 1 0 10%; text-align: left; padding-left: 30px;">
            <img src="/assets/img/dashboard/stripe-logo.png" height="66" />
        </div>
        <div style="flex: 1 0 65%;">
            <h2>Stripe</h2>
            <p>Financial services and software company that allows user to send payments</p>
        </div>
        <div style="flex: 1 0 25%;">
            <button
            class="connect-button">
                <div class="connect-button-text">Connect</div>
            </button>
        </div>

    </div>

    <div class="general-container top-margin">
            <div class="label">TEAM MEMBERS</div>
            <div class="add-payment-row">
                <img src="/assets/icons/circle-plus-bg.svg" width="35" height="35" />
                <span>Add a Team Member</span>
            </div>
    </div>

    <div class="payment-info-container">
        <ng-template [ngIf]="showTeamInfo">
            <div class="payment-container">
                <div class="payment-row payment-row-header">
                    <div class="label-item-first">MEMBER NAME</div>
                    <div class="label-item-second">EMAIL.</div>
                </div>


                <div class="payment-row">
                    <div class="label-item-first">Josh Russo</div>
                    <div class="label-item-second">J.Russo@elevareagency.com</div>
                </div>


            </div>

               
        </ng-template>
    </div>

    <div class="button-container">
        <button class="btn-save">Save</button>
        <button class="btn-cancel">Cancel</button>
    </div>

</div>
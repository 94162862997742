import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardOrderDetails } from '../dashboard/dashboard.model';
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-order-detail-popover',
  templateUrl: './order-detail-popover.component.html',
  styleUrls: ['./order-detail-popover.component.scss']
})
export class OrderDetailPopoverComponent implements OnInit {

  faStar = faStar;
  faCcMastercard = faCcMastercard;
  @Input()
  show = false;

  @Input()
  loadedOrderDetails: DashboardOrderDetails = null;

  @Input()
  fetchingOrderDetails: boolean = false;

  @Input()
  displayOrderDetailsTotal: number = 0;

  @Input()
  tripRating: number = 0;

  @Output() closePopup: EventEmitter<any> = new EventEmitter();

  close(): void {
    this.closePopup.emit();
  }

  constructor() { }

  ngOnInit(): void {
  }

}

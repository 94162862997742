
import { Component, OnInit } from '@angular/core';

import {
  DashboardEnter,
  DashboardEarnings,
  DashboardDriverPayments,
  DashboardActivity,
  DashboardOrderDetails
} from './dashboard.model';
import { DashboardService } from './dashboard.service';

const dashboardEnterErrorText: string = 'Error';
const dashboardEarningsErrorText: string = 'Error';
const dashboardDriverPaymentsErrorText = 'Error';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss', '../app.shared.scss']
})

export class DashboardComponent implements OnInit {
  loadedDashboardEnter: DashboardEnter = null;
  fetchingDashboardEnter: boolean = false;
  dashboardEnterError = null;

  loadedDashboardEarnings: DashboardEarnings = null;
  fetchingDashboardEarnings: boolean = false;
  dashboardEarningsError = null;

  timeFrame = 'year';
  driveTimeFrame = 'year';

  loadedDashboardDriverPayments: DashboardDriverPayments = null;
  fetchingDashboardDriverPayments: boolean = false;
  dashboardDriverPaymentsError = null;

  loadedDashboardActivity: DashboardActivity[];
  fetchingDashboardActivity: boolean = false;

  displayDashboardOrderDetails: boolean = false;
  loadedDashboardOrderDetails: DashboardOrderDetails = null;
  fetchingDashboardOrderDetails: boolean = false;
  displayDashboardOrderDetailsTotal: number = 0;

  tripRating = 0;

  dataTableColumns = ['Status', 'Order ID', 'Pick/Drop Address', 'Ride Cost', 'Earnings', 'Date'];


  dropdownFirstItem = 'Order ID       ';

  dropdownMenu = {
    Status: 'status',
    'Order ID': 'id',
    Date: 'date'
  };

  sortByItem;

  dropdownFirstTimeFrame = '1 Year';
  dropdownMenuTimeFrame = {
    'Last Month': 'lastMonth',
    'Six Months': 'sixMonths',
    Year: 'year',
    '2 Weeks': 'twoWeeks'
  };


  constructor(private dashboardService: DashboardService) {
  }


  ngOnInit(): void {
    this.fetchDashboardEnter();
    this.fetchEarnings();
    this.fetchDriverPayments();
    this.fetchDashboardActivity();
  }

  private fetchDashboardEnter(): void {
    this.fetchingDashboardEnter = true;
    this.dashboardService.fetchDashboardEnter().subscribe(dashboardEnter => {
      this.fetchingDashboardEnter = false;
      this.loadedDashboardEnter = dashboardEnter;
    }, dashboardEnterError => {
      this.fetchingDashboardEnter = false;
      console.log(dashboardEnterError);
      this.dashboardEnterError = dashboardEnterErrorText;
    });
  }

  private fetchEarnings(): void {
    this.fetchingDashboardEarnings = true;
    this.dashboardService.fetchEarnings().subscribe(dashboardEarnings => {
      this.fetchingDashboardEarnings = false;
      this.loadedDashboardEarnings = dashboardEarnings;
    }, dashboardEarningsError => {
      this.fetchingDashboardEarnings = false;
      console.log(dashboardEarningsError);
      this.dashboardEarningsError = dashboardEarningsErrorText;
    });
  }

  private fetchDriverPayments(): void {
    this.fetchingDashboardDriverPayments = true;
    this.dashboardService.fetchDriverPayments().subscribe(dashboardDriverPayments => {
      this.fetchingDashboardDriverPayments = false;
      this.loadedDashboardDriverPayments = dashboardDriverPayments;
    }, dashboardDriverPaymentsError => {
      this.fetchingDashboardDriverPayments = true;
      console.log(dashboardDriverPaymentsError);
      this.dashboardDriverPaymentsError = dashboardDriverPaymentsErrorText;
    });
  }

  private fetchDashboardActivity(): void {
    this.fetchingDashboardActivity = true;
    this.dashboardService.fetchDashboardActivity().subscribe(dashboardActivity => {
      this.fetchingDashboardActivity = false;
      this.loadedDashboardActivity = dashboardActivity;
    });
  }

  onSelectRecentActivityDetails(id: number): void {
    this.displayDashboardOrderDetails = true;
    this.fetchingDashboardOrderDetails = true;
    this.dashboardService.fetchDashboardOrderDetails(id).subscribe(dashboardOrderDetails => {
      this.fetchingDashboardOrderDetails = false;
      this.loadedDashboardOrderDetails = dashboardOrderDetails;
      this.tripRating = dashboardOrderDetails.tripRating.valueOf();

      for (const item of this.loadedDashboardOrderDetails.tripItems)  {
        this.displayDashboardOrderDetailsTotal += item.quantity;
      }
    });
  }

  closePopOver(): void {
    this.displayDashboardOrderDetails = false;
  }
}

<img src="../../assets/img/auth/entrega-logo-and-text.png" class="auth-logo"/>
<div class="signup-card">
    <div class="signup-card-content">
<h1 class="header">Sign Up</h1>
<div class="admin-signup-container">
    <div class="form-group">
        <label for="inputName" class="col-lg-2
        control-label"></label>
        <div class="col-lg-8">
            <input type="text" class = "form-control"  
              placeholder="First Name"
              required>
         </div>
    </div>
    <div class="form-group">
        <label for="inputName" class="col-lg-2
        control-label"></label>
        <div class="col-lg-8">
            <input type="text" class = "form-control"  
              placeholder="Last Name"
              required>
         </div>
    </div>
    <div class="form-group">
        <label for="email" class="col-lg-2
        control-label"></label>
        <div class="col-lg-8">
            <input type="text" class = "form-control"
              placeholder="Email"
              required>
         </div>
    </div>
    <div class="form-group ">
        <label for="inputPassword" class="col-lg-2 	control-label"></label>
        <div class="col-lg-8">
            <input type="password" class="form-control"
          ngModel name="pwd"
          id="inputPassword" placeholder="Password"
          minlength ="8" required
         >
         </div>
     </div>
    <button 
    type="submit" class="get-started-button" (click)="getStarted()">Get Started</button>
</div>
</div>
</div>
import { EventEmitter, Output } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit {

  @Input()
  title = 'Data';

  @Input()
  loading = false;

  @Input()
  dataRows = [];

  @Input()
  columns = [];
  constructor() { }

  @Input()
  dropDownItems = {};

  @Input()
  dropDownFirstItem = '';

  // @Output("sortByItemSelected") sortByItemSelected = new EventEmitter<string>();
  @Output() sortByItemSelected = new EventEmitter<string>();

  ngOnInit(): void {}

  sort(sortByItem): void {
    this.sortByItemSelected.emit(sortByItem.value);
    this.dropDownFirstItem = sortByItem.key;
  }

}


<div class="vehicle-registration-container" [class.is-open]="show">
    <div (click)="close()" style="margin-top: 5%;"><img src="../../assets/img/global/collapsed-back-button.png"></div>
    <div class='vehicle-registration-header'>
      Vehicle Registration</div>
    <hr />

    <app-driver-expiration-notice-popover title="Expired Registration" 
      message="Driver registration is past the expiration date. This driver will become inactive until vehicle registration is updated."
      [show]="displayExpiredNotice" 
      (closePopup)="closePopOver()">
    </app-driver-expiration-notice-popover>

    <div class="detail-row">
      <div class="label" style="padding-top: 2%;">VIN</div>
      <div class="val highlight">3PLHT801Y1S4065</div>
    </div>
    <div class="detail-row">
      <div class="label">Vehicle Owner</div>
      <div class="val highlight">Marcus Goodloe</div> 
    </div>
    <div class="detail-row">
      <div class="label">Make</div>
      <div class="val highlight"> Ford</div>
    </div>
    <div class="detail-row">
      <div class="label">Year</div>
      <div class="val highlight">2018</div>
    </div>
    <div class="detail-row">
        <div class="label">Effective Date</div>
        <div class="val highlight">08/24/2019</div>
    </div>
    <div class="detail-row">
        <div class="label">Expiration Date</div>
        <div class="val highlight">08/24/2025</div>
    </div>
    <div class="detail-row">
        <img class="registration-card" src="../../assets/img/drivers/registration-card-example.png" (click)="onClickRegistrationImage()">
    </div>
    <div class="detail-row">
        <div class="attachments">View Attachments</div>
    </div>
  </div> 





   

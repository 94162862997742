<div class="auth-container">
	<img src="../../assets/img/auth/entrega-logo-and-text.png" class="auth-logo"/>
	<div
		class="auth-credentials-container"
		*ngIf="isLoading; else elseBlock">
		<app-loading-spinner></app-loading-spinner>
	</div>
	<ng-template #elseBlock>
		<div class="auth-credentials-container">
			<div class="auth-credentials-welcome-back-text">Welcome Back</div>
			<form class="auth-login-form" #authForm="ngForm" (ngSubmit)="onSubmit(authForm)" *ngIf="!isLoading">
				<div class="auth-credentials-label">Email Address</div>
				<div class="auth-credentials-input-border">
					<div class="form-group">
						<input class="auth-input"
							type="email"
							id="email"
							ngModel
							name="email"
							required
							email
						/>
					</div>		
				</div>
				<div class="auth-credentials-label margin-before">Password</div>
				<div class="auth-credentials-input-border">
					<div class="form-group">
						<input class="auth-input"
							type="password"
							id="password"
							ngModel
							name="password"
							required
							minlength="6"
						/>
					</div>
				</div>
				<button
				class="auth-login-button"
				type="submit"
				[disabled]="!authForm.valid">
					<div class="auth-login-button-text">Login</div>
				</button>
				<div class="no-account-link">
						<p>Don't have an account yet? <a [routerLink]="['/signup']"> <span style="color:#ef4b24"><u>Sign Up</u></span></a></p>
				</div>
			</form>
			<div class="auth-login-error-container alert alert-danger" *ngIf="error">
				<div class="auth-login-error-text">{{ error }}</div>
			</div>
		</div>
	</ng-template>
</div>

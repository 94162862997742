<div class="order-detail-container" [class.is-open]="show"> 
  <div (click)="close()"><img src="../../assets/img/global/collapsed-back-button.png"></div>
  <div class='order-detail-header'>
    Order Details</div>
  <br />
  <div class="detail-row">
    <div class="label">Customer</div>
    <div class="val highlight big">{{ loadedOrderDetails?.customerNameFirst }} {{   
      loadedOrderDetails?.customerNameLast }}</div>
  </div>
  <br />
  <div class="detail-row">
    <div class="label">Payment Method</div>
    <div class="val small"><fa-icon [icon]="faCcMastercard"></fa-icon> **** **** {{ loadedOrderDetails?.paymentAccountNumberLastFourDigits }}</div>
  </div>
  <br />
  <div class="detail-row">
    <div class="label">Driver</div>
    <div class="val highlight">{{ loadedOrderDetails?.driverNameFirst }} {{
      loadedOrderDetails?.driverNameLast
      }}</div>
  </div>
  <br />
  <div class="detail-row">
    <div class="label">Confirmation Method</div>
    <div class="val highlight">{{ loadedOrderDetails?.confirmationMethod }}</div>
  </div>
  <div class="detail-row">
    <div class="val small">Signed {{ loadedOrderDetails?.confirmationTime }}</div> 
  </div>
  <br />
  <div class="detail-row">
    <div class="label">Trip Rating</div>
    <div class="val rating">
      <ng-template #t let-fill="fill">
        <span class="star" [class.full]="fill === 100">
          <span class="half" [style.width.%]="fill"><fa-icon [icon]="faStar"></fa-icon></span><fa-icon [icon]="faStar"></fa-icon>
        </span>
      </ng-template>
      <ngb-rating [max]="5" [(rate)]="tripRating" [readonly]="false" [starTemplate]="t"></ngb-rating>
    </div>
  </div>
  <div class="detail-row">
    <div class="label small">Comments</div>
    <div class="val small">{{ loadedOrderDetails?.tripComments }}</div>
  </div>
  <br />
  <div class="detail-row item-list-header ">
    <div class="label bold">Item List</div>
    <div class="val bold">Qty.</div>
  </div>
  <hr />
  <div class="list-container">
    <div class="detail-row" *ngFor="let loadedOrderDetailTripItem of loadedOrderDetails?.tripItems;">
      <div class="label"> {{ loadedOrderDetailTripItem.name }}
      </div>
      <div class="val"> {{ loadedOrderDetailTripItem.quantity }}
      </div>
    </div>
  </div>
  <hr />
    <div class="detail-row">
      <div class="label bold"> Total</div>
      <div class="val bold"> {{ displayOrderDetailsTotal }} 
    </div>
</div>
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-navigator-item',
  templateUrl: './navigator-item.component.html',
  styleUrls: ['./navigator-item.component.scss']
})
export class NavigatorItemComponent implements OnInit {

  constructor() { }

  @Input()
  title: string;

  @Input()
  link: string;

  @Input()
  img: string;

  ngOnInit(): void {
  }

}

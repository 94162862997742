// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  entregaDomainAndPort: 'https://entrega-dev.cofebe.com', // 'http://ec2-54-184-105-249.us-west-2.compute.amazonaws.com' replace with this value to test latest backend
  firebase: {
    apiKey: 'AIzaSyAZPaDKsUDOlYHpGIHun8vwLSGAjwbkhYY',
    authDomain: 'af-dev-34220.firebaseapp.com',
    databaseURL: 'https://af-dev-34220-default-rtdb.firebaseio.com',
    projectId: 'af-dev-34220',
    storageBucket: 'af-dev-34220.appspot.com',
    messagingSenderId: '156116638127',
    appId: '1:156116638127:web:132c40c6b669f61401775a',
    measurementId: 'G-2D0Y0BKGFS'
  }

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
